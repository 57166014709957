// TAGSs listing
export const GET_TAGS_LIST = "GET_TAGS_LIST";
export const GET_TAGS_LIST_SUCCESS = "GET_TAGS_LIST_SUCCESS";
export const GET_TAGS_LIST_ERROR = "GET_TAGS_LIST_ERROR";

//add new
export const ADD_TAG = "ADD_TAG";
export const ADD_TAGS_SUCCESS = "ADD_TAGS_SUCCESS";
export const ADD_TAGS_ERROR = "ADD_TAGS_ERROR";

//edit new
export const EDIT_TAG = "EDIT_TAG";
export const EDIT_TAGS_SUCCESS = "EDIT_TAGS_SUCCESS";
export const EDIT_TAGS_ERROR = "EDIT_TAGS_ERROR";

//delete
export const DELETE_TAG = "DELETE_TAG";
export const DELETE_TAGS_SUCCESS = "DELETE_TAGS_SUCCESS";
export const DELETE_TAGS_ERROR = "DELETE_TAGS_ERROR";

// Aisle listing
export const GET_RECIPE_CATEGORY_LIST = "GET_RECIPE_CATEGORY_LIST";
export const GET_RECIPE_CATEGORY_LIST_SUCCESS =
  "GET_RECIPE_CATEGORY_LIST_SUCCESS";
export const GET_RECIPE_CATEGORY_LIST_ERROR = "GET_RECIPE_CATEGORY_LIST_ERROR";

// Add Aisle
export const ADD_RECIPE_CATEGORY = "ADD_RECIPE_CATEGORY";
export const ADD_RECIPE_CATEGORY_SUCCESS = "ADD_RECIPE_CATEGORY_SUCCESS";
export const EDIT_RECIPE_CATEGORY_SUCCESS = "EDIT_RECIPE_CATEGORY_SUCCESS";
export const ADD_RECIPE_CATEGORY_ERROR = "ADD_RECIPE_CATEGORY_ERROR";

//delete Aisle
export const DELETE_RECIPE_CATEGORY = "DELETE_RECIPE_CATEGORY";
export const DELETE_RECIPE_CATEGORY_SUCCESS = "DELETE_RECIPE_CATEGORY_SUCCESS";
export const DELETE_RECIPE_CATEGORY_ERROR = "DELETE_RECIPE_CATEGORY_ERROR";

// RecipeCategory listing
export const GET_GOAL_LIST = "GET_GOAL_LIST";
export const GET_GOAL_LIST_SUCCESS = "GET_GOAL_LIST_SUCCESS";
export const GET_GOAL_LIST_ERROR = "GET_GOAL_LIST_ERROR";

// Add RecipeCategory
export const ADD_GOAL = "ADD_GOAL";
export const ADD_GOALS_SUCCESS = "ADD_GOALS_SUCCESS";
export const EDIT_GOALS_SUCCESS = "EDIT_GOALS_SUCCESS";
export const ADD_GOALS_ERROR = "ADD_GOALS_ERROR";

//delete RecipeCategory
export const DELETE_GOAL = "DELETE_GOAL";
export const DELETE_GOALS_SUCCESS = "DELETE_GOALS_SUCCESS";
export const DELETE_GOALS_ERROR = "DELETE_GOALS_ERROR";

// Goal listing
export const GET_AISLE_DATA_LIST = "GET_AISLE_DATA_LIST";
export const GET_AISLE_DATA_LIST_SUCCESS = "GET_AISLE_DATA_LIST_SUCCESS";
export const GET_AISLE_DATA_LIST_ERROR = "GET_AISLE_DATA_LIST_ERROR";

// Add Goal
export const ADD_AISLE_DATA = "ADD_AISLE_DATA";
export const ADD_AISLE_DATA_SUCCESS = "ADD_AISLE_DATA_SUCCESS";
export const ADD_AISLE_DATA_ERROR = "ADD_AISLE_DATA_ERROR";
export const EDIT_AISLE_DATA_SUCCESS = "EDIT_AISLE_DATA_SUCCESS";

//delete Goal
export const DELETE_AISLE_DATA = "DELETE_AISLE_DATA";
export const DELETE_AISLE_DATA_SUCCESS = "DELETE_AISLE_DATA_SUCCESS";
export const DELETE_AISLE_DATA_ERROR = "DELETE_AISLE_DATA_ERROR";

// List Product category

export const GET_PRODUCT_CATEGORY_LIST = "GET_PRODUCT_CATEGORY_LIST";
export const GET_PRODUCT_CATEGORY_LIST_SUCCESS =
  "GET_PRODUCT_CATEGORY_LIST_SUCCESS";
export const GET_PRODUCT_CATEGORY_LIST_ERROR =
  "GET_PRODUCT_CATEGORY_LIST_ERROR";

// Add Aisle
export const ADD_PRODUCT_CATEGORY = "ADD_PRODUCT_CATEGORY";
export const ADD_PRODUCT_CATEGORY_SUCCESS = "ADD_PRODUCT_CATEGORY_SUCCESS";
export const EDIT_PRODUCT_CATEGORY_SUCCESS = "EDIT_PRODUCT_CATEGORY_SUCCESS";
export const ADD_PRODUCT_CATEGORY_ERROR = "ADD_PRODUCT_CATEGORY_ERROR";

//delete Aisle
export const DELETE_PRODUCT_CATEGORY = "DELETE_PRODUCT_CATEGORY";
export const DELETE_PRODUCT_CATEGORY_SUCCESS =
  "DELETE_PRODUCT_CATEGORY_SUCCESS";
export const DELETE_PRODUCT_CATEGORY_ERROR = "DELETE_PRODUCT_CATEGORY_ERROR";

//setting data for edit
export const SET_TAGS_LIST = "SET_TAGS_LIST";

// set search of TAGSs
export const SET_TAGS_SEARCH = "SET_TAGS_SEARCH";

// set sortField of TAGSs
export const SET_TAGS_SORT_FIELD = "SET_TAGS_SORT_FIELD";

// set sortOrder of TAGSs
export const SET_TAGS_SORT_ORDER = "SET_TAGS_SORT_ORDER";

// set page of TAGSs
export const SET_TAGS_PAGE = "SET_TAGS_PAGE";
export const DRAG_DROP_CATEGORY = "DRAG_AND_DROP_CATEGORY";

