import produce from "immer";
import {
  ADD_INGREDIENT,
  ADD_INGREDIENT_ERROR,
  ADD_INGREDIENT_SUCCESS,
  DELETE_INGREDIENT,
  DELETE_INGREDIENT_ERROR,
  DELETE_INGREDIENT_SUCCESS,
  EDIT_INGREDIENT,
  EDIT_INGREDIENT_ERROR,
  EDIT_INGREDIENT_SUCCESS,
  GET_AISLE_LIST,
  GET_AISLE_LIST_ERROR,
  GET_AISLE_LIST_SUCCESS,
  GET_INGREDIENT_LIST,
  GET_INGREDIENT_LIST_ERROR,
  GET_INGREDIENT_LIST_SUCCESS,
  SET_INGREDIENTS_PAGE,
  SET_INGREDIENTS_SORT_FIELD,
  SET_INGREDIENTS_SORT_ORDER,
  SET_INGREDIENT_LIST,
  SET_INGREDIENT_SEARCH
} from "./actionTypes";

const initialState = {
  allIngredientsList: null,
  loading: false,
  error: "",
  totalCount: 0,
  totalPages: 0,
  limit: 20,
  page: 1,
  sortOrder: "",
  sortField: "",
  search: "",
  aisle_list: null,
  aisleLoad: false,
  aisleError: "",
  addIngredientLoad: false,
  deleteIngredientLoad: false
};

const ingredientsReducer = produce((state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_INGREDIENT_LIST:
      return {
        ...state,
        loading: true
      };

    //set ingredients list
    case GET_INGREDIENT_LIST_SUCCESS:
      return {
        ...state,
        allIngredientsList: payload.data,
        totalCount: payload.metadata.totalCount,
        totalPages: payload.metadata.totalPages,
        // page: payload.metadata.pages,
        limit: payload.metadata.limit,
        count: payload.metadata.count,
        loading: false
      };
    case GET_INGREDIENT_LIST_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    // set Ingredients search
    case SET_INGREDIENT_SEARCH:
      return {
        ...state,
        search: payload
      };
    // set Ingredients sortField
    case SET_INGREDIENTS_SORT_FIELD:
      return {
        ...state,
        sortField: payload
      };

    // set Ingredients sortOrder
    case SET_INGREDIENTS_SORT_ORDER:
      return {
        ...state,
        sortOrder: payload
      };
    // set Ingredients Page
    case SET_INGREDIENTS_PAGE:
      return {
        ...state,
        page: payload
      };
    case GET_AISLE_LIST:
      return {
        ...state,
        aisleLoad: true
      };
    case GET_AISLE_LIST_SUCCESS:
      return {
        ...state,
        aisle_list: payload,
        aisleLoad: false
      };
    case GET_AISLE_LIST_ERROR:
      return {
        ...state,
        aisleLoad: false,
        aisleError: payload
      };
    case ADD_INGREDIENT:
      return {
        ...state,
        addIngredientLoad: true
      };
    case ADD_INGREDIENT_SUCCESS:
      return {
        ...state,
        addIngredientLoad: false
      };
    case ADD_INGREDIENT_ERROR:
      return {
        ...state,
        addIngredientLoad: false
      };
    case EDIT_INGREDIENT:
      return {
        ...state,
        loading: true
      };
    case EDIT_INGREDIENT_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case EDIT_INGREDIENT_ERROR:
      return {
        ...state,
        loading: false
      };

    case SET_INGREDIENT_LIST:
      return {
        ...state,
        allIngredientsList: payload
      };

    //delete
    case DELETE_INGREDIENT:
      return {
        ...state,
        deleteIngredientLoad: true
      };
    case DELETE_INGREDIENT_SUCCESS:
      return {
        ...state,
        deleteIngredientLoad: false
      };
    case DELETE_INGREDIENT_ERROR:
      return {
        ...state,
        deleteIngredientLoad: false
      };
    default:
      return state;
  }
});

export default ingredientsReducer;
