import produce from "immer";
import {
  GET_TAGS_LIST,
  GET_TAGS_LIST_ERROR,
  GET_TAGS_LIST_SUCCESS
} from "modules/category/store/actionTypes";
import {
  ADD_RECIPE,
  ADD_RECIPE_ERROR,
  ADD_RECIPE_SUCCESS,
  DELETE_RECIPE,
  DELETE_RECIPE_ERROR,
  DELETE_RECIPE_SUCCESS,
  EDIT_RECIPE,
  EDIT_RECIPE_ERROR,
  EDIT_RECIPE_SUCCESS,
  EXPORT_RECIPE_STATUS_DATA,
  EXPORT_RECIPE_STATUS_DATA_ERROR,
  EXPORT_RECIPE_STATUS_DATA_SUCCESS,
  GET_CATEGORY_LIST,
  GET_CATEGORY_LIST_ERROR,
  GET_CATEGORY_LIST_SUCCESS,
  GET_RECIPE,
  GET_RECIPES_INGREDIENT_LIST,
  GET_RECIPES_INGREDIENT_LIST_ERROR,
  GET_RECIPES_INGREDIENT_LIST_SUCCESS,
  GET_RECIPE_ERROR,
  GET_RECIPE_LIST,
  GET_RECIPE_LIST_ERROR,
  GET_RECIPE_LIST_SUCCESS,
  GET_RECIPE_STATUS_LIST,
  GET_RECIPE_STATUS_LIST_ERROR,
  GET_RECIPE_STATUS_LIST_SUCCESS,
  GET_RECIPE_SUCCESS,
  RECIPE_STATUS_UPDATE,
  RECIPE_STATUS_UPDATE_ERROR,
  RECIPE_STATUS_UPDATE_SUCCESS,
  SET_RECIPES_PAGE,
  SET_RECIPES_SORT_FIELD,
  SET_RECIPES_SORT_ORDER,
  SET_RECIPE_LIST,
  SET_RECIPE_SEARCH,
  SET_RECIPE_STATUS
} from "./actionTypes";

const initialState = {
  allRecipeList: null,
  singleRecipe: null,
  singleRecipeLoad: null,
  singleRecipeError: "",
  loading: false,
  categoryList: null,
  categoryLoad: false,
  error: "",
  totalCount: 0,
  totalPages: 0,
  limit: 20,
  page: 1,
  sortOrder: "",
  sortField: "",
  search: "",
  status: "",
  addRecipeLoad: false,
  recipeStatusList: null,
  recipeStatusLoad: false,
  recipeStatusUpdateLoad: false,
  recipeStatusUpdateList: null,
  recipesIngredientLoading: false,
  deleteRecipeLoad: false,
  exportRecipeData: null,
  tagsList: null
};

const recipesReducer = produce((state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    //recipe list
    case GET_RECIPE_LIST:
      return {
        ...state,
        loading: true
      };
    case GET_RECIPE_LIST_SUCCESS:
      return {
        ...state,
        allRecipeList: payload.data,
        totalCount: payload?.metadata?.totalCount,
        totalPages: payload?.metadata?.totalPages,
        limit: payload?.metadata?.limit,
        count: payload?.metadata?.count,
        loading: false
      };
    case GET_RECIPE_LIST_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };

    case EXPORT_RECIPE_STATUS_DATA:
      return {
        ...state,
        loading: true
      };
    case EXPORT_RECIPE_STATUS_DATA_SUCCESS:
      return {
        ...state,
        exportRecipeData: payload,
        loading: false
      };
    case EXPORT_RECIPE_STATUS_DATA_ERROR:
      return {
        ...state,
        loading: false
      };

    //tags details
    case GET_TAGS_LIST:
      return {
        ...state,
        loading: true
      };
    case GET_TAGS_LIST_SUCCESS:
      return {
        ...state,
        tagsList: payload?.results,
        loading: false
      };
    case GET_TAGS_LIST_ERROR:
      return {
        ...state,
        loading: false
      };

    //single recipe details
    case GET_RECIPE:
      return {
        ...state,
        singleRecipeLoad: true
      };
    case GET_RECIPE_SUCCESS:
      return {
        ...state,
        singleRecipe: payload,
        singleRecipeLoad: false
      };
    case GET_RECIPE_ERROR:
      return {
        ...state,
        singleRecipeError: payload,
        singleRecipeLoad: false
      };

    //get recipe status filter
    case GET_RECIPE_STATUS_LIST:
      return {
        ...state,
        recipeStatusLoad: true
      };

    case GET_RECIPE_STATUS_LIST_SUCCESS:
      return {
        ...state,
        recipeStatusList: payload,
        recipeStatusLoad: false
      };
    case GET_RECIPE_STATUS_LIST_ERROR:
      return {
        ...state,
        recipeStatusLoad: false
      };
    //recipe status update
    case RECIPE_STATUS_UPDATE:
      return {
        ...state,
        recipeStatusUpdateLoad: true,
        loading: true
      };

    case RECIPE_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        recipeStatusUpdateList: payload,
        recipeStatusUpdateLoad: false,
        loading: false
      };
    case RECIPE_STATUS_UPDATE_ERROR:
      return {
        ...state,
        recipeStatusUpdateLoad: false,
        loading: false
      };

    //get ingredients list
    case GET_RECIPES_INGREDIENT_LIST:
      return {
        ...state,
        recipesIngredientLoading: true
      };

    case GET_RECIPES_INGREDIENT_LIST_SUCCESS:
      return {
        ...state,
        recipesAllIngredientsList: payload.data,
        recipesIngredientsTotalCount: payload.metadata.totalCount,
        recipesIngredientsTotalPages: payload.metadata.totalPages,
        // page: payload.metadata.pages,
        recipesIngredientslimit: payload.metadata.limit,
        recipesIngredientscount: payload.metadata.count,
        recipesIngredientLoading: false
      };
    case GET_RECIPES_INGREDIENT_LIST_ERROR:
      return {
        ...state,
        error: payload,
        recipesIngredientLoading: false
      };

    //category listing
    case GET_CATEGORY_LIST:
      return {
        ...state,
        categoryLoad: true
      };

    case GET_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        categoryList: payload,
        categoryLoad: false
      };
    case GET_CATEGORY_LIST_ERROR:
      return {
        ...state,
        categoryLoad: false
      };
    case ADD_RECIPE:
      return {
        ...state,
        addRecipeLoad: true
      };
    case ADD_RECIPE_SUCCESS:
      return {
        ...state,
        addRecipeLoad: false
      };
    case ADD_RECIPE_ERROR:
      return {
        ...state,
        addRecipeLoad: false
      };

    //edit
    case EDIT_RECIPE:
      return {
        ...state,
        addRecipeLoad: true
      };
    case EDIT_RECIPE_SUCCESS:
      return {
        ...state,
        addRecipeLoad: false
      };
    case EDIT_RECIPE_ERROR:
      return {
        ...state,
        addRecipeLoad: false
      };
    // set Recipe status
    case SET_RECIPE_STATUS:
      return {
        ...state,
        status: payload
      };

    // set Recipe search
    case SET_RECIPE_SEARCH:
      return {
        ...state,
        search: payload
      };
    // set Recipe sortField
    case SET_RECIPES_SORT_FIELD:
      return {
        ...state,
        sortField: payload
      };

    // set Recipe sortOrder
    case SET_RECIPES_SORT_ORDER:
      return {
        ...state,
        sortOrder: payload
      };
    // set Recipe Page
    case SET_RECIPES_PAGE:
      return {
        ...state,
        page: payload
      };

    case SET_RECIPE_LIST:
      return {
        ...state,
        allRecipeList: payload
      };

    //delete
    case DELETE_RECIPE:
      return {
        ...state,
        deleteRecipeLoad: true
      };
    case DELETE_RECIPE_SUCCESS:
      return {
        ...state,
        deleteRecipeLoad: false
      };
    case DELETE_RECIPE_ERROR:
      return {
        ...state,
        deleteRecipeLoad: false
      };
    default:
      return state;
  }
});

export default recipesReducer;
